import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type SEOProps = {
  description?: string
  lang?: string
  meta?: any
  keywords?: any
  title: string,
  ldJson?:any
}

const SEO: React.FunctionComponent<SEOProps> = ({
  description,
  lang,
  meta,
  title
}) => {
  const { wordpressSiteMetadata } = useStaticQuery(
    graphql`
      query {
        wordpressSiteMetadata {
          
            title
            description
           
          
        }
      }
    `
  );
  const site= wordpressSiteMetadata;

  if(description!==null && description!=="") {
    meta.push({
      name: 'description',
      content: description
    })
  }

  return (
      <>
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
    >
      <meta name="description" content={description}/>
    </Helmet>
        </>
  )
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

export default SEO
